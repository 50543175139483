<style lang="scss" scoped>
  .hb-button {
    font-family: 'Circular Std Bold';
    font-size: 1rem;
    text-transform: none;
    letter-spacing: normal;
    color: #fff;

    width: 100%;
    max-width: 480px;
    height: 56px !important;

    border-radius: 5rem;
    background-color: $brandPurple;
    border: 0;
    box-shadow: none !important;
    transition: all 150ms ease;

    &:hover {
      background-color: darken($brandPurple, 5%);
    }

    &:active {
      background-color: $brandDarkPurple;
    }

    &:disabled {
      opacity: 0.4;
      color: #fff;
      background-color: $brandPurple;
    }

    :deep(.v-btn__overlay) {
      opacity: 0 !important;
    }
  }
</style>

<template>
  <v-btn :ripple="false" :disabled="disabled" class="hb-button" @click="onClick">
    <slot />
  </v-btn>
</template>

<script setup>

defineProps({
  disabled: Boolean,
});

const emit = defineEmits(['click']);

function onClick () {
  emit('click');
}

</script>
